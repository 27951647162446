import React, { Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import BilihLogo from '../../Images/ghhfgh.svg';

const Footer = () => {
    return (
        <Fragment>
            <section className='pb-6'>
                <Container>
                    <Row className='mb-10'>
                        <Col className='mb-4 mb-lg-0' lg={3}>
                            <img src={BilihLogo} className=" md:max-w-md lg:max-w-lg xl:max-w-xl sm:max-w-xs" alt="Bilih Digital Logo" />
                            <p className='text__16'>Empowering businesses with creative digital solutions that transform tomorrow's possibilities into today's reality.</p>
                        </Col>
                        <Col className='flex w-full justify-evenly'>
                            <Col className='mb-4 col-6 mb-lg-0' lg={{ span: 2, offset: 1 }}>
                                <h5 className='font-medium text__16 mb-3 text-[#A3A3A3]'>Company</h5>
                                <div className="flex flex-wrap gap-3 font-medium text__16">
                                    <NavLink to="/services" className='inline-block w-full text-Mblack'>Services</NavLink>
                                    <NavLink to="/about" className='inline-block w-full text-Mblack'>About  Us</NavLink>
                                    <NavLink to="/contact" className='inline-block w-full text-Mblack'>Contact Us</NavLink>
                                </div>
                            </Col>
                            <Col className='mb-4 col-6 mb-lg-0' lg={2}>
                                <h5 className='font-medium text__16 mb-3 text-[#A3A3A3]'>Concerns</h5>
                                <div className="flex flex-wrap gap-3 font-medium text__16">
                                    <NavLink to="/terms" className='inline-block w-full text-Mblack'>Term of Services</NavLink>
                                    {/* <NavLink to="/faq" className='inline-block w-full text-Mblack'>FAQs</NavLink> */}
                                </div>
                            </Col>
                        </Col>

                    </Row>

                    <div className="relative flex flex-wrap items-center justify-center gap-4 lg:flex-nowrap lg:justify-between">
                        <p className='text__16 text-[#A3A3A3]'>© 2024 Bilih Digital</p>

                        <div className="flex items-center justify-center w-full gap-3 lg:absolute lg:left-1/2 lg:top-1/2 lg:-translate-x-1/2 lg:-translate-y-1/2 lg:w-auto">

                            {/* <a href="#!" target='_blank'>
                                <img src="./../images/sos (2).svg" alt="" />
                            </a> */}
                            <a href="https://www.instagram.com/bilih_digital/" target='_blank'>
                                <img src="./../images/sos (3).svg" alt="Bilih Digital Instagram" />
                            </a>
                            <a href="https://www.facebook.com/bilihdigital/" target='_blank'>
                                <img src="./../images/sos (4).svg" alt="Bilih Digital Facebook" />
                            </a>
                            <a href="https://www.linkedin.com/company/bilih-digital" target='_blank'>
                                <img src="./../images/sos (5).svg" alt="Bilih Digital Linkedin" />
                            </a>
                        </div>

                        <div className="flex items-center gap-3">
                            <NavLink to="/terms" className='font-medium text__16 text-[#525252]'>Terms of Service</NavLink>
                        </div>
                    </div>
                </Container>
            </section>
        </Fragment>
    )
}

export default Footer
